import React from "react"
import { customLoader, getOrigin } from "@temp/core/utils"
import Image from "next/image"
import { CustomImageProps } from "./types"

export const CustomImage: React.FC<CustomImageProps> = ({
  url,
  alt = "no image",
  objectFit = "contain",
  height,
  width,
  priority = false,
  layout = "intrinsic",
  placeholder = "empty",
  removeBg = false,
  sizes,
}) => {
  const origin = getOrigin()
  if (!url && height && width) {
    return (
      <Image
        src="/images/Product/no-photo.svg"
        layout="fixed"
        objectFit={objectFit}
        priority={priority}
        alt={alt}
        placeholder={placeholder}
        sizes={sizes}
        width={width}
        height={height}
      />
    )
  } if (!url) {
    return (
      <Image
        src="/images/Product/no-photo.svg"
        layout="fill"
        objectFit={objectFit}
        priority={priority}
        alt={alt}
        placeholder={placeholder}
        sizes={sizes}
      />
    )
  }
  const source = new URL(url, origin)
  const newUrl = removeBg ? `${url}#1` : `${url}#0`
  return ["https://dev-cdn.buyceps.com", "https://cdn.buyceps.com"].includes(
    source.origin
  ) ? (
    height ? (
      <Image
        loader={customLoader}
        width={width}
        height={height}
        src={newUrl || "/images/Product/no-photo.svg"}
        layout={layout}
        objectFit={objectFit}
        priority={priority}
        alt={alt}
        placeholder={placeholder}
        sizes={sizes}
      />
    ) : (
      <Image
        loader={customLoader}
        src={newUrl || "/images/Product/no-photo.svg"}
        layout="fill"
        objectFit={objectFit}
        priority={priority}
        alt={alt}
        placeholder={placeholder}
        sizes={sizes}
      />
    )
  ) : height ? (
    <Image
      width={width}
      height={height}
      src={url || "/images/Product/no-photo.svg"}
      layout={layout}
      objectFit={objectFit}
      priority={priority}
      alt={alt}
      placeholder={placeholder}
      sizes={sizes}
    />
  ) : (
    <Image
      src={url || "/images/Product/no-photo.svg"}
      layout="fill"
      objectFit={objectFit}
      priority={priority}
      alt={alt}
      placeholder={placeholder}
      sizes={sizes}
    />
  )
}
