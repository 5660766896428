import { gql } from "@apollo/client"

export const productCardFragment = gql`
  fragment ProductCardFragment on Product {
    id
    name
    stockQuantity
    neStockQuantity
    recommendationLevel
    lastPurchasedDate
    noOfTimesPurchased
    ratingsCount
    superSaver
    badge {
      id
      name
      color
    }
    productType {
      id
      name
    }
    firstImage {
      id
      url
      alt
    }
    brand {
      id
      name
      logo {
        url
      }
    }
    pricing {
      displayPrice {
        amount
      }
      displayListPrice {
        amount
      }
    }
    category {
      id
      name
    }
  }
`
export const comboProductCardFragment = gql`
  fragment ComboProductCardFragment on ComboProduct {
    id
    name
    stockQuantity
    neStockQuantity
    superSaver
    badge {
      id
      name
      color
    }
    firstImage {
      id
      url
      alt
    }
    brand {
      id
      name
      logo {
        url
      }
    }
    pricing {
      displayPrice {
        amount
      }
      displayListPrice {
        amount
      }
    }
    category {
      id
      name
      parent {
        id
        name
      }
    }
  }
`
