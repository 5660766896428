import { gql } from "@apollo/client"
import {
  comboProductCardFragment,
  productCardFragment,
} from "../ProductCard/queries"

export const SimilarProductsFragments = {
  product: gql`
    fragment SimilarProductsFragment on Product {
      id
      name
      category {
        id
        name
        parent {
          id
          name
        }
      }
    }
  `,
  comboProduct: gql`
    fragment SimilarComboProducts on ComboProduct {
      id
      name
      products {
        id
        category {
          id
          name
        }
      }
    }
  `,
}

export const similarProductsCategoryQuery = gql`
  query SimilarCategory($ids: [ID!]) {
    purchasables(categories: $ids, first: 9) {
      edges {
        node {
          id
          product {
            ...ProductCardFragment
          }
          comboProduct {
            ...ComboProductCardFragment
          }
        }
      }
    }
  }
  ${productCardFragment}
  ${comboProductCardFragment}
`
