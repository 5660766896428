import React from "react"

import * as S from "./styles"
import { CardContainerProps, CardStyle } from "./types"

export const CardContainer: React.FC<CardContainerProps> = ({
  cardType = CardStyle.productCard,
  children,
  cardHeight,
  padding,
  hasScrollParent,
}) => (
  <S.CardContainer
    cardHeight={cardHeight}
    hasScrollParent={hasScrollParent}
    padding={padding}
    cardStyle={cardType}
  >
    {children}
  </S.CardContainer>
)
