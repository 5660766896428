import { gql } from "@apollo/client"

export const ImageSliderFragments = {
  productImages: gql`
    fragment ProductImagesFragment on Product {
      id
      name
      attributes {
        attribute {
          id
          name
        }
        value {
          id
          name
        }
      }
      featuredUnboxingMedias(first: 6) {
        totalCount
        edges {
          node {
            id
            mediaType
            mediaUri
            thumbnailUrl
          }
        }
      }
      firstImage {
        id
        url
        alt
        removeBackground
      }
      variants {
        id
        name
        images {
          id
          url
          alt
          removeBackground
        }
      }
    }
  `,

  comboProductImages: gql`
    fragment ComboProductImagesFragment on ComboProduct {
      id
      name
      products {
        id
        attributes {
          attribute {
            id
            name
          }
          value {
            id
            name
          }
        }
      }
      firstImage {
        id
        url
        alt
        removeBackground
      }
      variations {
        id
        variants {
          id
          images {
            id
            url
            alt
            removeBackground
          }
        }
        images {
          id
          url
          alt
          removeBackground
        }
      }
    }
  `,
}
