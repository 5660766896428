import React from "react"

export enum CardStyle {
  productCard = "productCard",
  featuredBrands = "featuredBrands",
  buycepsExclusive = "buycepsExclusive",
  storeLocators = "storeLocators",
  productDetails = "productDetails",
  productDescription = "productDescription",
  productImagev2 = "productImagev2",
  productMobileAuth = "productMobileAuth",
  productMobileFastShipping = "productMobileFastShipping",
  ordersCard = "ordersCard",
  orderDetailsCard = "orderDetailsCard",
  homePageOrdersCard = "homePageOrdersCard",
  shippedAdresscard = "shippedAdresscard",
  accountcard = "accountcard",
  estimateDeliveryCard = "estimateDeliveryCard",
  attributesCardBlue = "attributesCardBlue",
  attributesCardPink = "attributesCardPink",
  authenticityCard = "authenticityCard",
  reviewCard = "reviewCard",
  reviewSubCard = "reviewSubCard",
  authenticitySubCard = "authenticitySubCard",
  faq = "faq",
  codCard = "codCard",
  codCardSelected = "codCardSelected",
  codCardWithoutBorder = "codCardWithoutBorder",
}

export interface CardContainerProps {
  cardType: CardStyle
  children?: React.ReactNode
  cardHeight?: number
  padding?: number
  hasScrollParent?: boolean
}
