import styled from "styled-components"

import * as C from "@temp/@next/globalStyles/constants"
import { CardStyle } from "./types"

const cardStyleType: { [key: string]: any } = {
  accountcard: {
    desktop: {
      width: "563px",
      height: "auto",

      padding: "22px 24px 23px 25px",
      borderRadius: "14px",
      backgroundImage: C.v2Theme.gradients.gradients2,
    },
    mobile: {
      width: "100%",
      height: "auto",

      padding: "34px 16px 21px 15px",
      borderRadius: "14px",
      backgroundImage: C.v2Theme.gradients.gradients2,
    },
  },
  productMobileAuth: {
    mobile: {
      width: "375px",

      margin: "0 0 24px",
      padding: "20px 20px 24px 22px",
      borderRadius: "14px",
      backgroundImage: "linear-gradient(to bottom, #fefeff, #f8faff 100%)",
    },
    desktop: {
      width: "375px",

      margin: "28px 19px 24px 18px",
      padding: "20px 20px 24px 22px",
      borderRadius: "14px",
      backgroundImage: "linear-gradient(to bottom, #fefeff, #f8faff 100%)",
    },
  },

  productMobileFastShipping: {
    mobile: {
      width: "100%",

      margin: "0 0 24px",
      padding: "25px 20px 18px 23px",
      borderRadius: "14px",
      backgroundImage: "linear-gradient(to bottom, #fefeff, #f8faff 100%)",
    },
    desktop: {
      width: "375px",

      margin: "0 0 24px",
      padding: "20px 20px 24px 22px",
      borderRadius: "14px",
      backgroundImage: "linear-gradient(to bottom, #fefeff, #f8faff 100%)",
    },
  },
  buycepsExclusive: {
    desktop: {
      width: "270px",
      height: "352px",
      borderRadius: "14px",
    },
    mobile: {
      width: "184px",
      height: "240px",
      borderRadius: "14px",
    },
  },

  productDetails: {
    desktop: {
      width: "661px",
      height: "auto",
      borderRadius: "14px",
      backgroundImage: "linear-gradient(to bottom, #fefeff, #f8faff 100%)",
      margin: "0 0 100px 0",
      padding: "24px",
      backdropFilter: "blur(30px)",
      boxShadow: "4px 4px 12px 0 rgba(0, 0, 0, 0.05)",
    },
    mobile: {
      width: "100vw",
      height: "auto",
      margin: "0 0 24px",
      padding: "26px 26px 21px 18px",
      borderRadius: "0px",
      backdropFilter: "blur(30px)",
      backgroundImage: "linear-gradient(to bottom, #fefeff, #f8faff 100%)",
    },
  },
  productDescription: {
    desktop: {
      width: "661px",
      height: "auto",
      margin: "14px 0px 0px 0px",
      padding: "35px 42px 25.2px 38px",
      borderRadius: "14px",
      backgroundImage: "linear-gradient(to bottom, #fefeff, #f8faff 100%)",
    },
    mobile: {
      width: "100vw",
      height: "auto",
      margin: "0 0 24px",
      padding: "25px 33px 26px 22px",
      borderRadius: "0px",
      backgroundImage: "linear-gradient(to bottom, #fefeff, #f8faff 100%)",
    },
  },

  productCard: {
    desktop: {
      width: "270px",
      height: "404px",
      padding: "10px 16px 45px",
      borderRadius: "14px",
      backgroundImage: "linear-gradient(to bottom, #fefeff, #f8faff 100%)",
      overflow: "initial",
      margin: "0px",
    },
    mobile: {
      height: "352px",
      width: "184px",
      padding: "5px 8px 28px",
      borderRadius: "14px",
      backgroundImage: C.v2Theme.gradients.gradients2,
      margin: "0 auto",
    },
  },
  shippedAdresscard: {
    desktop: {
      width: "857px",
      borderRadius: "0px 0px 14px 14px",
      padding: "28px",
      backgroundImage: C.v2Theme.gradients.gradients2,
    },
    mobile: {
      padding: "24px",
      borderRadius: "0px 0px 14px 14px",
      backgroundImage: C.v2Theme.gradients.gradients2,
      margin: "0 auto",
    },
  },
  ordersCard: {
    desktop: {
      width: "857px",
      height: "auto",
      padding: "0",
      borderRadius: "14px",
      backgroundImage: C.v2Theme.gradients.gradients2,
    },
    mobile: {
      width: "100%",
      height: "auto",
      padding: "0",
      borderRadius: "14px",
      backgroundImage: C.v2Theme.gradients.gradients2,
    },
  },
  orderDetailsCard: {
    desktop: {
      width: "857px",
      height: "auto",
      padding: "28px",
      borderRadius: "14px",
      backgroundImage: C.v2Theme.gradients.gradients2,
    },
    mobile: {
      width: "100%",
      height: "auto",
      padding: "24px",
      borderRadius: "14px",
      backgroundImage: C.v2Theme.gradients.gradients2,
    },
  },
  homePageOrdersCard: {
    desktop: {
      width: "100%",
      height: "155px",
      padding: "24px",
      borderRadius: "14px",
      backgroundImage: C.v2Theme.gradients.gradients2,
    },
    mobile: {
      width: "293px",
      height: "auto",
      padding: "22px",
      borderRadius: "14px",
      backgroundImage: C.v2Theme.gradients.gradients2,
    },
  },

  productImagev2: {
    desktop: {
      width: "465px",
      height: "590px",
      margin: "0px 24px 0px 0px",

      borderRadius: "14px",
      backgroundImage: "linear-gradient(to bottom, #fefeff, #f8faff 100%)",
      display: "flex",
      padding: "36px 60px",
      backdropFilter: "blur(30px)",
      boxShadow: "4px 4px 12px 0 rgba(0, 0, 0, 0.05)",
      border: "solid 2px #e2e9f0",
    },
    mobile: {
      width: "375px",
      height: "563px",
      margin: "17px 19px 28px 18px",
      padding: "42px 18px 38px 36px",
      borderRadius: "14px",
      backgroundImage: "linear-gradient(to bottom, #fefeff, #f8faff 100%)",
    },
  },
  codCardSelected: {
    desktop: {
      width: "110px",
      height: "306px",
      margin: "0 1px 49px 0",
      borderRadius: "14px",
      border: "solid 1px #de8e0e",
      backgroundColor: "#ffffff",
    },
    mobile: {
      width: "110px",
      height: "306px",
      margin: "0 1px 49px 0",
      borderRadius: "14px",
      border: "solid 1px #de8e0e",
      backgroundColor: "#ffffff",
    },
  },
  codCardWithoutBorder: {
    desktop: {
      width: "110px",
      height: "306px",
      margin: "0 1px 49px 0",
      borderRadius: "14px",
      border: "solid 1px #dbe2ea",
      backgroundColor: "#ffffff",
    },
    mobile: {
      width: "110px",
      height: "306px",
      margin: "0 1px 49px 0",
      borderRadius: "14px",
      border: "solid 1px #dbe2ea",
      backgroundColor: "#ffffff",
    },
  },
  codCard: {
    desktop: {
      width: "110px",
      height: "306px",
      margin: "0 1px 49px 0",
      padding: "0px",
      borderRadius: "14px",
      border: "solid 1px #44a228",
      backgroundImage: "linear-gradient(to bottom, #fefeff, #f8faff 100%)",
    },
    mobile: {
      width: "110px",
      height: "306px",
      margin: "0 1px 49px 0",
      padding: "0px",
      borderRadius: "14px",
      border: "solid 1px #44a228",
      backgroundImage: "linear-gradient(to bottom, #fefeff, #f8faff 100%)",
    },
  },
  featuredBrands: {
    desktop: {
      borderRadius: "14px",
      width: "1152px",
      height: "394px",
      backgroundImage: "linear-gradient(to bottom, #fefeff, #f8faff 100%)",

      padding: "48px 0px 28px 48px",
      margin: "76px auto 0px",
      overflow: "hidden",
    },
    mobile: {
      borderRadius: "14px",
      width: "380px",
      height: "330px",
      backgroundImage: "linear-gradient(to bottom, #fefeff, #f8faff 100%)",
      padding: "24px 0px 8px 0px",
      margin: "36px auto 0px",
    },
    imageContainer: {
      desktop: {
        height: "72px",
        minWidth: "156px",
        padding: "20px",
        border: `1px solid ${C.v2Theme.backgroundColors.gray4}`,
        borderRadius: "14px",
        margin: "12px",
        marginTop: "14px",
        backgroundColor: `${C.v2Theme.backgroundColors.white}`,
      },
      mobile: {
        height: "72px",
        minWidth: "102px",
        padding: "20px",
        margin: "6px",
        border: `1px solid ${C.v2Theme.backgroundColors.gray4}`,
        marginTop: "12px",
      },
    },
  },
  storeLocators: {
    desktop: {
      borderRadius: "14px",
      width: "1152px",
      height: "634px",
      backgroundImage: "linear-gradient(to bottom, #fefeff, #f8faff 100%)",
      padding: "0px",
      margin: "27px auto",
      overflow: "hidden",
    },
    mobile: {
      borderRadius: "14px",
      width: "370px",
      height: "auto",
      backgroundImage: "linear-gradient(to bottom, #fefeff, #f8faff 100%)",

      padding: "0px",
      margin: "36px auto 48px",
    },
  },
  estimateDeliveryCard: {
    desktop: {
      padding: "17px 24px 18px",
      borderRadius: "14px",
      backdropFilter: "blur(30px)",
      backgroundImage: "linear-gradient(to bottom, #fefeff, #f8faff)",
      margin: "-66px 0px 0px",
    },
    mobile: {
      width: "100vw",
      padding: "16px 16px 21px",
      borderRadius: "0px",
      backdropFilter: "blur(30px)",
      backgroundImage: "linear-gradient(to bottom, #fefeff, #f8faff)",
      margin: "24px 0px 0px",
    },
  },
  attributesCardBlue: {
    desktop: {
      width: "100%",
      padding: "20px 13px 12px 13px",
      borderRadius: "14px",
      backgroundImage: "linear-gradient(to top, #e8f1fc, #f2f5fa)",
    },
    mobile: {
      width: "100%",
      height: "138px",
      padding: "20px 13px 12px 13px",
      borderRadius: "14px",
      backgroundImage: "linear-gradient(to top, #e8f1fc, #f2f5fa)",
    },
  },
  attributesCardPink: {
    desktop: {
      width: "100%",
      padding: "20px 13px 12px 13px",
      borderRadius: "14px",
      backgroundImage: "linear-gradient(to top, #f0e8fc, #f2f5fa)",
    },
    mobile: {
      width: "100%",
      height: "138px",
      padding: "20px 13px 12px 13px",
      borderRadius: "14px",
      backgroundImage: "linear-gradient(to top, #f0e8fc, #f2f5fa)",
    },
  },
  authenticityCard: {
    desktop: {
      width: "563px",
      height: "auto",

      padding: "17px 24.3px 18px 26.7px",
      borderRadius: "14px",
      backgroundImage: C.v2Theme.gradients.gradients2,
    },
    mobile: {
      width: "100vw",
      height: "auto",

      padding: "28px 17px 16px 17.7px",
      borderRadius: "0px",
      backgroundImage: C.v2Theme.gradients.gradients2,
    },
  },
  reviewCard: {
    desktop: {
      width: "563px",
      height: "auto",

      padding: "17px 24.3px 18px 26.7px",
      borderRadius: "14px",
      // background: "#f8fafc",
      backgroundImage: C.v2Theme.gradients.gradients2,
    },
    mobile: {
      width: "100vw",
      height: "auto",

      padding: "28px 14px 16px 12px",
      borderRadius: "0px",
      background: "#f8fafc",
    },
  },
  reviewSubCard: {
    desktop: {
      // width: "363px",
      height: "auto",

      padding: "17px 24.3px 18px 26.7px",
      border: "1px solid #EFF3F9",
      borderRadius: "14px",
      background: "#fff",
      // backgroundImage: C.v2Theme.gradients.gradients2,
    },
    mobile: {
      // width: "356px",
      height: "auto",
      marginRight: "0px",
      padding: "28px 7px 16px 20.7px",
      border: "2px solid #EFF3F9",
      borderRadius: "14px",
      // background: "#fff",
      backgroundImage: C.v2Theme.gradients.gradients2,
    },
  },
  faq: {
    desktop: {
      width: "100%",
      height: "auto",
      padding: "17px 24.3px 18px 26.7px",
      borderRadius: "14px",
      backgroundImage: C.v2Theme.gradients.gradients2,
    },
    mobile: {
      width: "100%",
      height: "auto",
      padding: "18.9px 14.5px 26px 16px",
      borderRadius: "14px",
      backdropFilter: "blur(30px)",
      backgroundImage: C.v2Theme.gradients.gradients2,
    },
  },
  authenticitySubCard: {
    desktop: {
      width: "126.7px",
      minWidth: "126.7px",
      height: "130px",
      border: "solid 1px #e4eaf2",
      padding: "32px 0px 20px 18px",
      borderRadius: "29px",
      background: "#ffffff",
    },
    mobile: {
      width: "126.7px",
      minWidth: "126.7px",
      height: "130px",
      border: "solid 1px #e4eaf2",
      padding: "32px 0px 20px 18px",
      borderRadius: "29px",
      background: "#ffffff",
    },
  },
}

export const CardContainer = styled.div<{
  cardStyle: CardStyle
  cardHeight?: number
  padding?: number
  hasScrollParent?: boolean
}>`
  ${({ cardStyle, cardHeight, padding, hasScrollParent }) => `
            ${cardStyle !== "productImagev2" && `width: 100%`} ;
            margin: ${cardStyleType[cardStyle].desktop.margin};
            padding: ${cardStyleType[cardStyle].desktop.padding};
            border-radius: ${cardStyleType[cardStyle].desktop.borderRadius};
            height: ${cardStyleType[cardStyle].desktop.height};
            display: ${cardStyleType[cardStyle].desktop.display};
            border: ${cardStyleType[cardStyle].desktop.border};
            box-shadow: ${cardStyleType[cardStyle].desktop.boxShadow};
            //backdrop-filter: ${cardStyleType[cardStyle].desktop.backdropFilter};

            max-width: ${cardStyleType[cardStyle].desktop.width};
            min-width: ${cardStyleType[cardStyle].desktop.minWidth};

            background-image: ${cardStyleType[cardStyle].desktop.backgroundImage};
            overflow: ${cardStyleType[cardStyle].desktop.overflow};
            background: ${cardStyleType[cardStyle].desktop.background};  
            
            @media(max-width: ${C.smallScreen}px){
                padding: ${cardStyleType[cardStyle].mobile.padding};
                ${
                  hasScrollParent && padding
                    ? `padding-left: calc(((100vw - 50px) / 2) * 0.06)`
                    : ""
                };
                ${
                  hasScrollParent && padding
                    ? `padding-right: calc(((100vw - 50px) / 2) * 0.06)`
                    : ""
                };
                ${hasScrollParent ? `width: calc((100vw - 50px) / 2)` : ""};
                border-radius: ${cardStyleType[cardStyle].mobile.borderRadius};
                height: ${
                  hasScrollParent && cardHeight
                    ? `${cardHeight}px`
                    : cardStyleType[cardStyle].mobile.height
                };
                ${hasScrollParent ? `min-width: 158px` : ""};
                max-width: ${cardStyleType[cardStyle].mobile.width};
                width: ${
                  cardStyle !== "productCard" &&
                  cardStyle !== "buycepsExclusive" &&
                  cardStyleType[cardStyle].mobile.width
                };
                margin: ${cardStyleType[cardStyle].mobile.margin};
                overflow: ${cardStyleType[cardStyle].desktop.overflow};
                border: ${cardStyleType[cardStyle].mobile.border};
                background-color: ${
                  cardStyleType[cardStyle].mobile.backgroundColor
                };;
            }
        `}
`
CardContainer.displayName = "S.CardContainer"
