import { gql } from "@apollo/client"

export const BreadcrumbsFragments = {
  productPage: gql`
    fragment ProductPageBreadcrumbsFragment on Product {
      id
      name
      category {
        id
        name
        ancestors(last: 1) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
    }
  `,

  comboProductPage: gql`
    fragment ComboProductPageBreadcrumbsFragment on ComboProduct {
      id
      name
    }
  `,
}
