import { gql } from "@apollo/client"

export const SeoFragments = {
  product: gql`
    fragment ProductSeo on Product {
      id
      name
      h1Tag
      minVariantId
      seoTitle
      seoDescription
      descriptionJson
      description
      review {
        id
        rating
        bestRating
        aggregateRating
        author
        count
      }
      firstImage {
        id
        url
      }
      availability {
        available
      }
      pricing {
        priceRange {
          start {
            gross {
              amount
            }
            currency
          }
        }
      }
      attributes {
        attribute {
          id
          name
        }
        value {
          id
          name
          value: name
        }
      }
      brand {
        id
        name
        url
        logo {
          url
        }
      }
      category {
        id
        name
        ancestors(last: 1) {
          edges {
            node {
              id
              name
            }
          }
        }
      }
      variants {
        id
        name
        gtin
        stockQuantity
        neStockQuantity
        expiryDate: displayExpiryDate(format: "%b %-Y") {
          value
        }
        seoExpiryDate: displayExpiryDate(format: "%Y-%m-%d") {
          value
        }
        seoNEExpiryDate: neDisplayExpiryDate(format: "%Y-%m-%d") {
          value
        }
        pricing {
          price {
            gross {
              amount
            }
          }
          nePrice {
            gross {
              amount
            }
          }
        }
        images {
          id
          url
        }
      }
    }
  `,
  comboProduct: gql`
    fragment ComboProductSeo on ComboProduct {
      id
      name
      url
      h1Tag
      seoTitle
      seoDescription
      isAvailable
      description
      category {
        id
        name
      }
      firstImage {
        id
        url
        alt
      }
      pricing {
        priceRange {
          start {
            gross {
              amount
            }
            currency
          }
        }
      }
    }
  `,
}
