import { gql } from "@apollo/client"

export const MobilePriceAndQuantityFragments = {
  product: gql`
    fragment ProductPriceAndQuantityFragment on Product {
      id
      name
      category {
        id
        name
      }
      variants {
        id
        name
        stockQuantity
        neStockQuantity
        pricing {
          nePrice {
            gross {
              amount
            }
          }
          price {
            gross {
              amount
            }
          }
          listPrice {
            gross {
              amount
            }
          }
        }
      }
    }
  `,
  comboProduct: gql`
    fragment ComboProductPriceAndQuantityFragment on ComboProduct {
      id
      name
      variations {
        id
        stockQuantity
        pricing {
          price {
            gross {
              amount
            }
          }
          listPrice {
            gross {
              amount
            }
          }
        }
      }
    }
  `,
}
